.terminal {
  font-family: "Fira Code", monospace;
  font-size: 14px;
  color: #c1c1c1;
  background-color: #1c1c1c;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.terminal-header {
  height: 30px;
  background-color: #292929;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.terminal-header-button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
}

.terminal-header-button-close {
  background-color: #ff5f56;
}

.terminal-header-button-minimize {
  background-color: #ffbd2e;
}

.terminal-header-button-expand {
  background-color: #27c93f;
}

.terminal-header-title {
  color: #c1c1c1;
  margin: auto;
}

.terminal-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #292929;
  padding: 10px;
  overflow-y: auto;
}

/* .terminal-prompt {
  color: #ff5f56;
  margin-right: 5px;
} */
.terminal-form {
  display: flex;
  align-items: center;
  font-family: "Fira Code", monospace;
  margin-bottom: 1rem;
}

.date-time {
  margin-right: 10px;
}

.terminal-prompt {
  color: #00ff00;
}

.terminal-input {
  background: transparent;
  border: none;
  color: #fff;
  font-family: "Fira Code", monospace;
  font-size: inherit;
  margin-left: 0.25rem;
  width: 100%;
  outline: none;
}

.terminal-input:focus {
  outline: none;
}

.terminal-input::placeholder {
  color: #666;
}

.terminal-output {
  margin-bottom: 10px;
}

.terminal-error {
  color: #ff5f56;
}

.terminal-link {
  color: #5fa8d3;
  text-decoration: underline;
  cursor: pointer;
}

.terminal-code {
  color: #f1f1f1;
  font-size: 12px;
  font-family: "Fira Code", monospace;
  margin: 10px 0;
  padding: 5px;
  background-color: #292929;
  border-radius: 5px;
}

.terminal-code-block {
  white-space: pre-wrap;
  overflow-x: auto;
  margin: 10px 0;
}

.terminal-code-block::-webkit-scrollbar {
  width: 5px;
}

.terminal-code-block::-webkit-scrollbar-thumb {
  background-color: #5fa8d3;
  border-radius: 5px;
}

.terminal-code-block::-webkit-scrollbar-track {
  background-color: #292929;
}
